<template>
  <SectionCard v-if="!isEmpty(items)" label="История изменения">
    <TimeLine :items="items" />
  </SectionCard>
</template>

<script>
import SectionCard from '@/components/inspect/SectionCard/SectionCard';
import TimeLine from './TimeLine.vue';
import { mapActions, mapGetters } from 'vuex';
import {isEmpty} from 'lodash-es';
export default {
  name: 'AccountApplicationTimeLine',
  components: {
    TimeLine,
    SectionCard,
  },
  props: {
    id: { type: String, required: true },
    isDetail: { type: Boolean },
  },
  computed: {
    ...mapGetters({
      pending: 'accountApplication/pending',
      timeline: 'accountApplication/timeline',
      timelineAccount: 'account/timeline',
    }),
    items() {
      return this.isDetail ? this.timelineAccount : this.timeline;
    }
  },
  methods: {
    isEmpty,
    ...mapActions({
      fetchTimeline: 'accountApplication/fetchTimeline',
      fetchAccountTimeline: 'account/fetchTimeline',
    }),
  },
  watch: {
    state: {
      immediate: true,
      handler() {
        if (this.isDetail) this.fetchAccountTimeline({ id: this.id });
        else this.fetchTimeline({ id: this.id });
      }
    }
  }
}
</script>

<style module lang="scss">

</style>
